import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Ci2030Service } from "src/app/services/ci2030.service";
import { GlobalService } from "src/app/services/global.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: "app-ci2030-laboratorio",
  templateUrl: "./ci2030-laboratorio.component.html",
  styleUrls: ["./ci2030-laboratorio.component.css"],
})
export class Ci2030LaboratorioComponent implements OnInit {
  public laboratorio = {
    cod_lab: 0,
    nombre: "",
    descripcion: "",
    img: "",
    base64: "",
    pdf: "",
    base64_pdf: "",
  };

  public pdf: SafeHtml;

  constructor(
    private _Router: Router,
    private _ActivatedRoute: ActivatedRoute,
    private _Ci2030Service: Ci2030Service,
    private _GlobalService: GlobalService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this._ActivatedRoute.paramMap.subscribe((params) => {
      if (params.has("id")) {
        let id = params.get("id");
        this.GetCi2030LaboratorioId(id);
      } else {
        this._Router.navigate(["/"]);
      }
    });
  }

  public GetCi2030LaboratorioId(id) {
    this._Ci2030Service.GetCi2030LaboratorioId(id).subscribe(
      (result) => {
        if (result.status == 200) {
          let datos: any = result.body;
          datos.base64 =
            this._GlobalService.UrlArchivos() +
            "ci2030/laboratorios/" +
            datos.img;
          datos.base64_pdf =
            this._GlobalService.UrlArchivos() +
            "ci2030/laboratoriosPDF/" +
            datos.pdf;

          let frame = `<iframe src="${datos.base64_pdf}" style="width:500px; height:500px;" frameborder="0"></iframe>`;
          let frame_san: SafeHtml =
            this.sanitizer.bypassSecurityTrustHtml(frame);
          this.pdf = frame_san;

          this.laboratorio = datos;
          console.log("ci2030_laboratorio", JSON.stringify(datos));
        } else {
          console.log("result", result);
          this._GlobalService.ToastError();
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("An error occurred:", err.error.message);
          this._GlobalService.ToastError();
        }
      }
    );
  }
}
