import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Ci2030Service } from "src/app/services/ci2030.service";
import { GlobalService } from "src/app/services/global.service";

@Component({
  selector: "app-ci2030-botonera-labs",
  templateUrl: "./ci2030-botonera-labs.component.html",
  styleUrls: ["./ci2030-botonera-labs.component.css"],
})
export class Ci2030BotoneraLabsComponent implements OnInit {
  buttons = [];

  buttonRows = [];

  constructor(
    private _Ci2030Service: Ci2030Service,
    private _GlobalService: GlobalService
  ) {}

  ngOnInit(): void {
    this.GetCi2030Laboratorio();
  }

  public GetCi2030Laboratorio() {
    this._Ci2030Service.GetCi2030Laboratorio().subscribe(
      (result) => {
        if (result.status == 200) {
          let datos: any = result.body;
          datos.forEach((element) => {
            element.base64 =
              this._GlobalService.UrlArchivos() +
              "ci2030/laboratorios/" +
              element.img;

            let info = {
              cod_lab: element.cod_lab,
              title: element.nombre,
              icon: "business",
            };
            this.buttons.push(info);
          });
          console.log("ci2030_laboratorio", datos);
          this.buttonRows = this.chunkArray(this.buttons, 3);
        } else {
          console.log("result", result);
          this._GlobalService.ToastError();
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("An error occurred:", err.error.message);
          this._GlobalService.ToastError();
        }
      }
    );
  }

  chunkArray(array: any[], size: number): any[][] {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  }
}
