import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "../../services/global.service";
import { HexagonoService } from "../../services/hexagono.service";
import { HttpErrorResponse } from "@angular/common/http";
import { DepartamentoService } from "../../services/departamento.service";
import { TranslateService } from "@ngx-translate/core";
import { CarreraService } from "../../services/carrera.service";
import { SliderService } from "../../services/slider.service";
import { GraphService } from "src/app/services/graph.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
declare var VanillaTilt;

@Component({
  selector: "app-facultad-recursos",
  templateUrl: "./facultad-recursos.component.html",
  styleUrls: ["./facultad-recursos.component.css"],
})
export class FacultadRecursosComponent implements OnInit {
  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";

  public activeLang = "es";

  public ListadoHexagono: any;
  public Loading: boolean;
  public imgPrincipal = "assets/imgs/historia/facultad.png";

  public marca = "assets/imgs/recursos/LOGO CON RRSS-14.png";
  public principal_1 = "assets/imgs/recursos/MARCA FACULTAD AZUL-14.png";
  public principal_2 = "assets/imgs/recursos/MARCA FACULTAD BLANCA-14.png";
  public formulario_1 = "assets/imgs/recursos/formulario_1.png";
  public formulario_2 = "assets/imgs/recursos/formulario_2.png";
  public formulario_3 = "assets/imgs/recursos/formulario_3.png";
  public presentacion_1 = "assets/imgs/recursos/presentacion_1.png";
  public presentacion_2 = "assets/imgs/recursos/presentacion_2.png";
  public manual = "assets/imgs/recursos/manual.png";
  public manual_acoso = "assets/imgs/recursos/manual_acoso.png";

  public formulario_1doc =
    "assets/imgs/recursos/FORMULARIO APOYO GESTION LOGISTICA FACULTAD CIENCIAS.docx";
  public formulario_2doc = "assets/imgs/recursos/PLANTILLA CARTA FACULTAD.docx";
  public formulario_3doc =
    "assets/imgs/recursos/PLANTILLA OFICIO FACULTAD.docx";

  public presentacion_1ppt =
    "assets/imgs/recursos/PLANTILLA FACULTAD AZUL.pptx";
  public presentacion_2ppt =
    "assets/imgs/recursos/PLANTILLA FACULTAD - copia.pptx";
  public manual_pdf = "assets/imgs/recursos/manual.pdf";

  public manual_acoso_pdf = "assets/imgs/recursos/manual_acoso.pdf";

  public recursos_corporativos = [];

  constructor(
    private _Router: Router,
    private _GlobalService: GlobalService,
    private _SliderService: SliderService,
    private _HexagonoService: HexagonoService,
    private _DepartamentoService: DepartamentoService,
    private _CarreraService: CarreraService,
    private translate: TranslateService,
    private _GraphService: GraphService,
    public sanitizer: DomSanitizer
  ) {
    this.reload();

    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);

    this.ListadoHexagono = [];
    this.ListadoBanner = [];
    this.GETBanner();
    this.QueryRecursoCorporativo();
  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-facultad-historia"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-facultad-historia", JSON.stringify(false));
      window.location.reload();
    }
  }

  public RedirectBanner(url) {
    window.open(url);
  }

  public QueryRecursoCorporativo() {
    this._GraphService.QueryRecursoCorporativo().subscribe(
      (result) => {
        if (result.status == 200) {
          let datos: any = result.body;
          this.recursos_corporativos = datos.data.recursos_corporativos;

          this.recursos_corporativos.forEach((rec) => {
            rec.documentos.forEach((doc) => {
              let video: SafeHtml;
              let word = doc.img.search("doc");
              let ppt = doc.img.search("ppt");
              let pdf = doc.img.search("pdf");

              let url =
                "http://ciencias.ubiobio.cl/web/mantenedor_v2/views/uploads/recurso_corporativo/" +
                rec.cod_recurso +
                "/" +
                doc.img;

              if (word != -1) {
                let frame = `<iframe src="http://docs.google.com/gview?url=${url}&embedded=true" style="width:500px; height:500px;" frameborder="0"></iframe>`;
                let frame_san: SafeHtml =
                  this.sanitizer.bypassSecurityTrustHtml(frame);
                doc.frame = frame_san;
              } else if (ppt != -1) {
                let frame = `<iframe src="http://docs.google.com/gview?url=${url}&embedded=true" style="width:500px; height:500px;" frameborder="0"></iframe>`;
                let frame_san: SafeHtml =
                  this.sanitizer.bypassSecurityTrustHtml(frame);
                doc.frame = frame_san;
              } else if (pdf != -1) {
                let frame = `<iframe src="${url}" style="width:500px; height:500px;" frameborder="0"></iframe>`;
                let frame_san: SafeHtml =
                  this.sanitizer.bypassSecurityTrustHtml(frame);
                doc.frame = frame_san;
              } else {
                let frame = `<div class="card-img card-img-zoom">
                <a class="example-image-link" href="${url}"
                    data-lightbox="example-1"><img src="${url}"
                        class="img-principal" /></a>
            </div>`;
                let frame_san: SafeHtml =
                  this.sanitizer.bypassSecurityTrustHtml(frame);
                doc.frame = frame_san;
              }
            });
          });
          console.log(this.recursos_corporativos);
        } else {
          console.log("result", result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("An error occurred:", err.error.message);
        }
      }
    );
  }

  public GETBanner() {
    this._SliderService.GETBannerId(2).subscribe(
      (result) => {
        if (result.status == 200) {
          let datos: any = result.body;
          let listado = [];
          let i = 0;
          datos.forEach((element) => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log("result", result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("An error occurred:", err.error.message);
        }
      }
    );
  }

  ngOnInit() {
    VanillaTilt.init(document.querySelector(".tilt-image"), {
      max: 25,
      speed: 400,
    });

    VanillaTilt.init(document.querySelectorAll(".tilt-image"));
  }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

  public AbrirDocumento(tipo) {
    let url = "";
    switch (tipo) {
      case 1:
        // url = "assets/imgs/recursos/FORMULARIO APOYO GESTION LOGISTICA FACULTAD CIENCIAS.docx";
        url = this.principal_1;
        break;
      case 2:
        url = this.principal_2;
        break;
      case 3:
        url = this.marca;
        break;
      case 4:
        url = this.formulario_1doc;
        break;
      case 5:
        url = this.formulario_2doc;
        break;
      case 6:
        url = this.presentacion_1ppt;
        break;
      case 7:
        url = this.presentacion_2ppt;
        break;
      case 8:
        url = this.formulario_3doc;
        break;
      case 9:
        url = this.manual_pdf;
        break;
      case 10:
        url = this.manual_acoso_pdf;
        break;
    }
    window.open(url);
  }
}
